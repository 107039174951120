var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "hosting-transfer-form" }, [
    _c(
      "form",
      { ref: "hosting-transfer-form" },
      [
        _c(
          "validation-observer",
          { ref: "observer" },
          [
            _c(
              "b-row",
              { staticClass: "px-4 mt-4" },
              [
                _c(
                  "b-col",
                  [
                    _c("label", [_vm._v("Hosting Provider")]),
                    _c(
                      "validation-provider",
                      { attrs: { name: "provider", rules: "required" } },
                      [
                        _c("b-form-input", {
                          attrs: {
                            type: "text",
                            state: _vm.errorState("provider"),
                          },
                          on: {
                            blur: function ($event) {
                              _vm.inputDirty.provider = true
                            },
                          },
                          model: {
                            value: _vm.transferRequest.provider,
                            callback: function ($$v) {
                              _vm.$set(_vm.transferRequest, "provider", $$v)
                            },
                            expression: "transferRequest.provider",
                          },
                        }),
                        _c(
                          "b-form-invalid-feedback",
                          { attrs: { id: "domain-error-feedback" } },
                          [
                            _vm._v(
                              "\n              Provider is required\n            "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "b-row",
              { staticClass: "px-4" },
              [
                _c(
                  "b-col",
                  [
                    _c("label", [_vm._v("Username")]),
                    _c(
                      "validation-provider",
                      { attrs: { name: "username", rules: "required" } },
                      [
                        _c("b-form-input", {
                          attrs: {
                            type: "text",
                            state: _vm.errorState("username"),
                          },
                          on: {
                            blur: function ($event) {
                              _vm.inputDirty.username = true
                            },
                          },
                          model: {
                            value: _vm.transferRequest.username,
                            callback: function ($$v) {
                              _vm.$set(_vm.transferRequest, "username", $$v)
                            },
                            expression: "transferRequest.username",
                          },
                        }),
                        _c(
                          "b-form-invalid-feedback",
                          { attrs: { id: "domain-error-feedback" } },
                          [
                            _vm._v(
                              "\n              Username is required\n            "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-col",
                  [
                    _c("label", [_vm._v("Password")]),
                    _c(
                      "validation-provider",
                      { attrs: { name: "password", rules: "required" } },
                      [
                        _c(
                          "b-input-group",
                          [
                            _c("b-form-input", {
                              attrs: {
                                type: _vm.passwordInputType,
                                state: _vm.errorState("password"),
                              },
                              on: {
                                blur: function ($event) {
                                  _vm.inputDirty.password = true
                                },
                              },
                              model: {
                                value: _vm.transferRequest.password,
                                callback: function ($$v) {
                                  _vm.$set(_vm.transferRequest, "password", $$v)
                                },
                                expression: "transferRequest.password",
                              },
                            }),
                            _c(
                              "b-input-group-append",
                              { staticClass: "password-visibility" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "password-icon-container",
                                    class: _vm.passwordIconBorder,
                                  },
                                  [
                                    _c("fa-icon", {
                                      staticClass: "show-password-icon",
                                      attrs: {
                                        icon: _vm.showPassword
                                          ? "eye"
                                          : "eye-slash",
                                        type: "button",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.showPassword = !_vm.showPassword
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "b-form-invalid-feedback",
                              { attrs: { id: "domain-error-feedback" } },
                              [
                                _vm._v(
                                  "\n                Password is required\n              "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "b-row",
              { staticClass: "px-4" },
              [
                _c("b-col", [
                  _c("span", { staticClass: "text--error" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.errorMessage) +
                        "\n          "
                    ),
                  ]),
                ]),
              ],
              1
            ),
            _c(
              "b-row",
              { staticClass: "px-4 py-3" },
              [
                _c(
                  "b-col",
                  { attrs: { cols: "auto" } },
                  [
                    _c("div", [_vm._v("Need help with this transfer?")]),
                    _c(
                      "b-checkbox",
                      {
                        staticClass: "text--teal",
                        nativeOn: {
                          click: function ($event) {
                            _vm.inputDirty.requestCall = true
                          },
                        },
                        model: {
                          value: _vm.transferRequest.requestCall,
                          callback: function ($$v) {
                            _vm.$set(_vm.transferRequest, "requestCall", $$v)
                          },
                          expression: "transferRequest.requestCall",
                        },
                      },
                      [_vm._v("\n            Request a call\n          ")]
                    ),
                  ],
                  1
                ),
                _c("b-col"),
                _c(
                  "b-col",
                  { attrs: { cols: "auto" } },
                  [
                    _vm.cancellable
                      ? _c(
                          "b-button",
                          {
                            staticClass: "mr-2",
                            attrs: {
                              variant: "outline-danger",
                              disabled: _vm.loading,
                            },
                            on: { click: _vm.closeModal },
                          },
                          [_vm._v("\n            Cancel\n          ")]
                        )
                      : _vm._e(),
                    _c(
                      "b-button",
                      {
                        attrs: { variant: "primary", disabled: _vm.loading },
                        on: { click: _vm.submitRequest },
                      },
                      [
                        _c(
                          "b-overlay",
                          {
                            attrs: {
                              show: _vm.loading,
                              rounded: "sm",
                              opacity: 0,
                              variant: "black",
                            },
                          },
                          [_c("span", [_vm._v(_vm._s(_vm.submitText))])]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }